/* src/components/MerchantForm.css */

.merchant-form {
    max-width: 1500px;  /* Wider form for 4 columns */
    margin: 10px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .merchant-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;  /* Larger font size for the title */
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;  /* Spacing between columns */
    margin-bottom: 20px;  /* Space between rows */
  }
  
  .form-column {
    flex: 1;
    min-width: 180px;  /* Adjust for 4 columns */
  }
  
  label {
    display: block;
    font-size: 14px;  /* Larger font size */
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  input,
  select {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #f9f9f9;
    box-sizing: border-box;
  }
  
  input[type="file"] {
    padding: 12px;
    font-size: 14px;
    background-color: #f1f1f1;
    border: 2px solid #4CAF50;
    border-radius: 12px;
    box-sizing: border-box;
  }
  
  input[type="time"] {
    padding: 8px;
  }
  
  .submitButton {
    padding: 16px 30px;  /* Increase the padding for a larger button */
    font-size: 18px;      /* Larger font size */
    background-color: gray;
    color: #fff;
    border: none;
    border-radius: 8px;   /* Slightly larger rounded corners */
    cursor: pointer;
    width: 100%;          /* Makes the button span the full width of the form */
    transition: background-color 0.3s ease;
    margin-top: 30px; 
  }
  

  
  /* Premium styling for Brand Logo */
  .premium-logo input {
    background-color: #f1f1f1;
    border: 2px solid #4CAF50;
    border-radius: 12px;
    padding: 15px;
    font-size: 14px;
    color: #333;
  }
  
  .time-management label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  