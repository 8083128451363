/* src/components/VoucherCreateForm.css */
.voucher-form-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  input,
  select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  input:focus,
  select:focus {
    border-color: #4caf50;
    outline: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    color: #fff;
    background-color: gray;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
