.paddingAll{

    padding: 10px 10px 10px 10px;
}
.pricetitle{
    font-weight: 600;
}

.icon{
    margin-top: 20px;
    margin-left: 20px;

}
.iconColor{
    color: #ECEFF1;
}

.card1gradient{
    background: rgb(245,245,245);
    background: linear-gradient(145deg, white 0%, lightgray 100%);

    
}
.card2gradient{
    background: rgb(240,240,240);
    background: linear-gradient(145deg, white 0%, lightgray 100%);
}

.card3gradient{
    background: rgb(240,240,240);
    background: linear-gradient(145deg, white 0%, lightgray 100%);
}
.card4gradient{
    background: rgb(240,240,240);
    background: linear-gradient(145deg, white 0%, lightgray 100%);
}


.bgcolor{
    min-height: 100vh;
    background: #ECEFF1;
    background: linear-gradient(158deg,rgb(224,224,224,) 0%,rgb(233,237,254) 100%);

}


