* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: rgb(245,245,245);
    background: linear-gradient(145deg, white 0%, lightgray 100%);
}

.login-container {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-header {
    text-align: center;
    margin-bottom: 2rem;
}

.login-header h1 {
    color: #2c3e50;
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: #2c3e50;
    font-weight: 600;
}

.form-group input {
    padding: 0.8rem;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    outline: none;
    border-color: #3498db;
}

.error-message {
    color: #e74c3c;
    font-size: 0.9rem;
    display: none;
}

.login-button {
    background: #3498db;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease;
}

.login-button:hover {
    background: #2980b9;
}

.additional-options {
    margin-top: 1.5rem;
    text-align: center;
}

.additional-options a {
    color: #3498db;
    text-decoration: none;
    font-size: 0.9rem;
}

.additional-options a:hover {
    text-decoration: underline;
}

@media (max-width: 480px) {
    .login-container {
        margin: 1rem;
        padding: 1.5rem;
    }
}