/* src/components/MerchantProfile.css */

.merchant-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    background-color: #fff;
    border-radius: 10px;

  }
  
  .profile-image-container {
    text-align: center;

  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Maintains aspect ratio */
    border: 4px solid #4CAF50; /* Adds a premium border */
  }
  
  .profile-info {
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .merchant-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;

  }
  
  .details {
    text-align: left; /* Aligns the details to the left */
    width: 100%;
    max-width: 400px; /* Ensures it doesn't span too wide */
  }
  
  .details p {
    font-size: 13px;
    color: #555;
    margin: 5px 0;
  }
  
  .details .title {
    font-weight: bold;
    color: #333;
    margin-right: 5px; /* Adds space between the title and value */
  }
  